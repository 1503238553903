<template>
  <div v-if="Object.keys(getBet).length > 0">
    <div class="bpr desktop-section">
      <div class="bpr__pao">
        <div class="bpr__pao__containerinfo">
          <div class="bpr__pao__containerinfo__table1">
            <div class="bpr__pao__containerinfo__table1__rowt1">
              <div class="bpr__pao__containerinfo__table1__rowt1__col1"></div>
              <div class="bpr__pao__containerinfo__table1__rowt1__col2"></div>
              <div class="bpr__pao__containerinfo__table1__rowt1__col3">
                <img src="https://az755631.vo.msecnd.net/golstats-bets/caliente_2020.svg" width="57" />
              </div>
            </div>
            <div class="bpr__pao__containerinfo__table1__rowt2">
              <div class="bpr__pao__containerinfo__table1__rowt2__col1 ttext-1">Moneyline</div>
              <div class="bpr__pao__containerinfo__table1__rowt2__col2 ttext-2">Probabilidad</div>
              <div class="bpr__pao__containerinfo__table1__rowt2__col3 ttext-3">Momio</div>
            </div>
            <div
              v-if="getBet['Moneyline'][1].result"
              class="bpr__pao__containerinfo__table2__rowt3"
              :class="{
                'green-select': moneyLineMaxProbName === getBetId(getBet['Moneyline'][1]),
              }"
            >
              <div class="bpr__pao__containerinfo__table2__rowt3__col1 ttext-4">
                <div class="bpr__pao__containerinfo__table2__rowt3__col1__result">
                  &bull;&nbsp;&nbsp;{{ getBet['Moneyline'][1].result }}
                </div>
                <template v-if="getBet['Moneyline'][1].teamAcronym">
                  <div class="bpr__pao__containerinfo__table2__rowt3__col1__team-logo">
                    <img :src="this.getTeamLogoUrl(getBet['Moneyline'][1].teamLogo)" alt="" />
                  </div>
                  <div v-if="isAcronymVisible">
                    {{ getBet['Moneyline'][1].teamAcronym }}
                  </div>
                </template>
              </div>
              <div class="bpr__pao__containerinfo__table2__rowt3__col2 ttext-5">{{ getBet['Moneyline'][1].prob }}%</div>
              <div
                class="bpr__pao__containerinfo__table2__rowt3__col3 ttext-6"
                @click="openCalienteBet(getBet['Moneyline'][1])"
              >
                <template v-if="getBet['Moneyline'][1].odd < 1">{{ getBet['Moneyline'][1].odd }}</template>
                <template v-else>+{{ getBet['Moneyline'][1].odd }}</template>
              </div>
            </div>
            <div
              v-if="getBet['Moneyline'][2].result"
              class="bpr__pao__containerinfo__table2__rowt3"
              :class="{
                'green-select': moneyLineMaxProbName === getBetId(getBet['Moneyline'][2]),
              }"
            >
              <div class="bpr__pao__containerinfo__table2__rowt3__col1 ttext-4">
                <div class="bpr__pao__containerinfo__table2__rowt3__col1__result">
                  &bull;&nbsp;&nbsp;{{ getBet['Moneyline'][2].result }}
                </div>
                <template v-if="getBet['Moneyline'][2].teamAcronym">
                  <div class="bpr__pao__containerinfo__table2__rowt3__col1__team-logo">
                    <img :src="this.getTeamLogoUrl(getBet['Moneyline'][2].teamLogo)" alt="" />
                  </div>
                  <div v-if="isAcronymVisible">
                    {{ getBet['Moneyline'][2].teamAcronym }}
                  </div>
                </template>
              </div>
              <div class="bpr__pao__containerinfo__table2__rowt3__col2 ttext-5">{{ getBet['Moneyline'][2].prob }}%</div>
              <div
                class="bpr__pao__containerinfo__table2__rowt3__col3 ttext-6"
                @click="openCalienteBet(getBet['Moneyline'][2])"
              >
                <template v-if="getBet['Moneyline'][2].odd < 1">{{ getBet['Moneyline'][2].odd }}</template>
                <template v-else>+{{ getBet['Moneyline'][2].odd }}</template>
              </div>
            </div>
            <div
              v-if="getBet['Moneyline'][0].result"
              class="bpr__pao__containerinfo__table2__rowt3"
              :class="{
                'green-select': moneyLineMaxProbName === getBetId(getBet['Moneyline'][0]),
              }"
            >
              <div class="bpr__pao__containerinfo__table2__rowt3__col1 ttext-4">
                <div class="bpr__pao__containerinfo__table2__rowt3__col1__result">
                  &bull;&nbsp;&nbsp;{{ getBet['Moneyline'][0].result }}
                </div>
                <template v-if="getBet['Moneyline'][0].teamAcronym">
                  <div class="bpr__pao__containerinfo__table2__rowt3__col1__team-logo">
                    <img :src="this.getTeamLogoUrl(getBet['Moneyline'][0].teamLogo)" alt="" />
                  </div>
                  <div v-if="isAcronymVisible">
                    {{ getBet['Moneyline'][0].teamAcronym }}
                  </div>
                </template>
              </div>
              <div class="bpr__pao__containerinfo__table2__rowt3__col2 ttext-5">{{ getBet['Moneyline'][0].prob }}%</div>
              <div
                class="bpr__pao__containerinfo__table2__rowt3__col3 ttext-6"
                @click="openCalienteBet(getBet['Moneyline'][0])"
              >
                <template v-if="getBet['Moneyline'][0].odd < 1">{{ getBet['Moneyline'][0].odd }}</template>
                <template v-else>+{{ getBet['Moneyline'][0].odd }}</template>
              </div>
            </div>
          </div>

          <div class="bpr__pao__containerinfo__table2">
            <div class="bpr__pao__containerinfo__table2__rowt2">
              <div
                class="bpr__pao__containerinfo__table2__rowt2__col1 ttext-1"
                v-if="getBet['Doble'][0].result || getBet['Doble'][1].result"
              >
                Doble Oportunidad
              </div>
              <div class="bpr__pao__containerinfo__table2__rowt2__col2"></div>
              <div class="bpr__pao__containerinfo__table2__rowt2__col3"></div>
            </div>
            <div
              v-if="getBet['Doble'][1].result"
              class="bpr__pao__containerinfo__table1__rowt3"
              :class="{
                'green-select':
                  getBet['MaxProb'][0].prob == getBet['Doble'][1].prob && getBet['MaxProb'][0].type_team == 1,
              }"
            >
              <div class="bpr__pao__containerinfo__table1__rowt3__col1 ttext-4">
                <div class="bpr__pao__containerinfo__table2__rowt3__col1__result">
                  &bull;&nbsp;&nbsp;{{ getBet['Doble'][1].result }}
                </div>
                <template v-if="getBet['Doble'][1].teamAcronym">
                  <div class="bpr__pao__containerinfo__table2__rowt3__col1__team-logo">
                    <img :src="this.getTeamLogoUrl(getBet['Doble'][1].teamLogo)" alt="" />
                  </div>
                  <div v-if="isAcronymVisible">
                    {{ getBet['Doble'][1].teamAcronym }}
                  </div>
                </template>
              </div>
              <div class="bpr__pao__containerinfo__table1__rowt3__col2 ttext-5">{{ getBet['Doble'][1].prob }}%</div>
              <div
                class="bpr__pao__containerinfo__table1__rowt3__col3 ttext-6"
                @click="openCalienteBet(getBet['Doble'][1])"
              >
                <template v-if="getBet['Doble'][1].odd < 1">{{ getBet['Doble'][1].odd }}</template>
                <template v-else>+{{ getBet['Doble'][1].odd }}</template>
              </div>
            </div>
            <div
              v-if="getBet['Doble'][0].result"
              class="bpr__pao__containerinfo__table1__rowt3"
              :class="{
                'green-select':
                  getBet['MaxProb'][0].prob == getBet['Doble'][0].prob && getBet['MaxProb'][0].type_team == 0,
              }"
            >
              <div class="bpr__pao__containerinfo__table1__rowt3__col1 ttext-4">
                <div class="bpr__pao__containerinfo__table2__rowt3__col1__result">
                  &bull;&nbsp;&nbsp;{{ getBet['Doble'][0].result }}
                </div>
                <template v-if="getBet['Doble'][0].teamAcronym">
                  <div class="bpr__pao__containerinfo__table2__rowt3__col1__team-logo">
                    <img :src="this.getTeamLogoUrl(getBet['Doble'][0].teamLogo)" alt="" />
                  </div>
                  <div v-if="isAcronymVisible">
                    {{ getBet['Doble'][0].teamAcronym }}
                  </div>
                </template>
              </div>
              <div class="bpr__pao__containerinfo__table1__rowt3__col2 ttext-5">{{ getBet['Doble'][0].prob }}%</div>
              <div
                class="bpr__pao__containerinfo__table1__rowt3__col3 ttext-6"
                @click="openCalienteBet(getBet['Doble'][0])"
              >
                <template v-if="getBet['Doble'][0].odd < 1">{{ getBet['Doble'][0].odd }}</template>
                <template v-else>+{{ getBet['Doble'][0].odd }}</template>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bpr__hp">
        <div class="bpr__hp__containerinfo">
          <div class="bpr__hp__containerinfo__rowimg">
            <template v-if="getBet['MaxProb'][0].logo == 'none'">
              <img :src="'https://az755631.vo.msecnd.net/teams-150/none.png'" width="124" />
            </template>
            <template v-else>
              <img :src="'https://az755631.vo.msecnd.net/teams-150/' + getBet['MaxProb'][0].logo" width="124" />
            </template>
          </div>
          <div class="bpr__hp__containerinfo__rowtitle">{{ getBet['MaxProb'][0].result }}</div>
          <div class="bpr__hp__containerinfo__rowstat">
            <div class="bpr__hp__containerinfo__rowstat__item">
              <div class="bpr__hp__containerinfo__rowstat__item__percent">{{ getBet['MaxProb'][0].prob }}%</div>
              <div class="bpr__hp__containerinfo__rowstat__item__title">Probabilidad</div>
            </div>
            <div class="bpr__hp__containerinfo__rowstat__sep">|</div>
            <div class="bpr__hp__containerinfo__rowstat__item">
              <div
                class="bpr__hp__containerinfo__rowstat__item__percent odd_textdecoration"
                @click="openCalienteBet(getBet['MaxProb'][0])"
              >
                {{ getBet['MaxProb'][0].odd }}
              </div>
              <div class="bpr__hp__containerinfo__rowstat__item__title">Momio</div>
            </div>
          </div>
          <div class="bpr__hp__containerinfo__rowprecision">
            <div class="btn-precision">
              <div class="btn-precision__text">Precisión GolStats: -</div>
              <div class="btn-precision__iconcont"><div class="icon-question">?</div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bpr movil-section">
      <div class="bpr__hp">
        <div class="bpr__hp__containerinfo">
          <div class="bpr__hp__containerinfo__rowimg">
            <template v-if="getBet['MaxProb'][0].logo == 'none'">
              <img :src="'https://az755631.vo.msecnd.net/teams-150/none.png'" width="124" />
            </template>
            <template v-else>
              <img :src="'https://az755631.vo.msecnd.net/teams-150/' + getBet['MaxProb'][0].logo" width="124" />
            </template>
          </div>
          <div class="bpr__hp__containerinfo__rowtitle">{{ getBet['MaxProb'][0].result }}</div>
          <div class="bpr__hp__containerinfo__rowstat">
            <div class="bpr__hp__containerinfo__rowstat__item">
              <div class="bpr__hp__containerinfo__rowstat__item__percent">{{ getBet['MaxProb'][0].prob }}%</div>
              <div class="bpr__hp__containerinfo__rowstat__item__title">Probabilidad</div>
            </div>
            <div class="bpr__hp__containerinfo__rowstat__sep">|</div>
            <div class="bpr__hp__containerinfo__rowstat__item">
              <div
                class="bpr__hp__containerinfo__rowstat__item__percent odd_textdecoration"
                @click="openCalienteBet(getBet['MaxProb'][0])"
              >
                <template v-if="getBet['MaxProb'][0].odd < 1">{{ getBet['MaxProb'][0].odd }}</template>
                <template v-else>+{{ getBet['MaxProb'][0].odd }}</template>
              </div>
              <div class="bpr__hp__containerinfo__rowstat__item__title">Momio</div>
            </div>
          </div>
          <div class="bpr__hp__containerinfo__rowprecision">
            <div class="btn-precision">
              <div class="btn-precision__text">Precisión GolStats: -</div>
              <div class="btn-precision__iconcont"><div class="icon-question">?</div></div>
            </div>
          </div>
        </div>
      </div>
      <div class="bpr__pao">
        <div class="bpr__pao__containerinfo">
          <div class="bpr__pao__containerinfo__table1">
            <div class="bpr__pao__containerinfo__table1__rowt1">
              <div class="bpr__pao__containerinfo__table1__rowt1__col1"></div>
              <div class="bpr__pao__containerinfo__table1__rowt1__col2"></div>
              <div class="bpr__pao__containerinfo__table1__rowt1__col3">
                <img src="https://az755631.vo.msecnd.net/golstats-bets/caliente_2020.svg" width="57" />
              </div>
            </div>
            <div class="bpr__pao__containerinfo__table1__rowt2">
              <div class="bpr__pao__containerinfo__table1__rowt2__col1 ttext-1">Moneyline</div>
              <div class="bpr__pao__containerinfo__table1__rowt2__col2 ttext-2">Probabilidad</div>
              <div class="bpr__pao__containerinfo__table1__rowt2__col3 ttext-3">Momio</div>
            </div>
            <div
              class="bpr__pao__containerinfo__table2__rowt3"
              :class="{
                'green-select': moneyLineMaxProbName === getBetId(getBet['Moneyline'][1]),
              }"
            >
              <div class="bpr__pao__containerinfo__table2__rowt3__col1 ttext-4">
                <div class="bpr__pao__containerinfo__table2__rowt3__col1__result">
                  &bull;&nbsp;&nbsp;{{ getBet['Moneyline'][1].result }}
                </div>
                <template v-if="getBet['Moneyline'][1].teamAcronym">
                  <div class="bpr__pao__containerinfo__table2__rowt3__col1__team-logo">
                    <img :src="this.getTeamLogoUrl(getBet['Moneyline'][1].teamLogo)" alt="" />
                  </div>
                  <div v-if="isAcronymVisible">
                    {{ getBet['Moneyline'][1].teamAcronym }}
                  </div>
                </template>
              </div>
              <div class="bpr__pao__containerinfo__table2__rowt3__col2 ttext-5">{{ getBet['Moneyline'][1].prob }}%</div>
              <div
                class="bpr__pao__containerinfo__table2__rowt3__col3 ttext-6"
                @click="openCalienteBet(getBet['Moneyline'][1])"
              >
                <template v-if="getBet['Moneyline'][1].odd < 1">{{ getBet['Moneyline'][1].odd }}</template>
                <template v-else>+{{ getBet['Moneyline'][1].odd }}</template>
              </div>
            </div>
            <div
              class="bpr__pao__containerinfo__table2__rowt3"
              :class="{
                'green-select': moneyLineMaxProbName === getBetId(getBet['Moneyline'][2]),
              }"
            >
              <div class="bpr__pao__containerinfo__table2__rowt3__col1 ttext-4">
                <div class="bpr__pao__containerinfo__table2__rowt3__col1__result">
                  &bull;&nbsp;&nbsp;{{ getBet['Moneyline'][2].result }}
                </div>
                <template v-if="getBet['Moneyline'][2].teamAcronym">
                  <div class="bpr__pao__containerinfo__table2__rowt3__col1__team-logo">
                    <img :src="this.getTeamLogoUrl(getBet['Moneyline'][2].teamLogo)" alt="" />
                  </div>
                  <div v-if="isAcronymVisible">
                    {{ getBet['Moneyline'][2].teamAcronym }}
                  </div>
                </template>
              </div>
              <div class="bpr__pao__containerinfo__table2__rowt3__col2 ttext-5">{{ getBet['Moneyline'][2].prob }}%</div>
              <div
                class="bpr__pao__containerinfo__table2__rowt3__col3 ttext-6"
                @click="openCalienteBet(getBet['Moneyline'][2])"
              >
                <template v-if="getBet['Moneyline'][2].odd < 1">{{ getBet['Moneyline'][2].odd }}</template>
                <template v-else>+{{ getBet['Moneyline'][2].odd }}</template>
              </div>
            </div>
            <div
              class="bpr__pao__containerinfo__table2__rowt3"
              :class="{
                'green-select': moneyLineMaxProbName === getBetId(getBet['Moneyline'][0]),
              }"
            >
              <div class="bpr__pao__containerinfo__table2__rowt3__col1 ttext-4">
                <div class="bpr__pao__containerinfo__table2__rowt3__col1__result">
                  &bull;&nbsp;&nbsp;{{ getBet['Moneyline'][0].result }}
                </div>
                <template v-if="getBet['Moneyline'][0].teamAcronym">
                  <div class="bpr__pao__containerinfo__table2__rowt3__col1__team-logo">
                    <img :src="this.getTeamLogoUrl(getBet['Moneyline'][0].teamLogo)" alt="" />
                  </div>
                  <div v-if="isAcronymVisible">
                    {{ getBet['Moneyline'][0].teamAcronym }}
                  </div>
                </template>
              </div>
              <div class="bpr__pao__containerinfo__table2__rowt3__col2 ttext-5">{{ getBet['Moneyline'][0].prob }}%</div>
              <div
                class="bpr__pao__containerinfo__table2__rowt3__col3 ttext-6"
                @click="openCalienteBet(getBet['Moneyline'][0])"
              >
                <template v-if="getBet['Moneyline'][0].odd < 1">{{ getBet['Moneyline'][0].odd }}</template>
                <template v-else>+{{ getBet['Moneyline'][0].odd }}</template>
              </div>
            </div>
          </div>
          <div class="bpr__pao__containerinfo__table2">
            <div class="bpr__pao__containerinfo__table2__rowt2">
              <div class="bpr__pao__containerinfo__table2__rowt2__col1 ttext-1">Doble Oportunidad</div>
              <div class="bpr__pao__containerinfo__table2__rowt2__col2"></div>
              <div class="bpr__pao__containerinfo__table2__rowt2__col3"></div>
            </div>
            <div
              :class="{
                'green-select':
                  getBet['MaxProb'][0].prob == getBet['Doble'][1].prob && getBet['MaxProb'][0].type_team == 1,
              }"
              class="bpr__pao__containerinfo__table1__rowt3"
            >
              <div class="bpr__pao__containerinfo__table1__rowt3__col1 ttext-4">
                <div class="bpr__pao__containerinfo__table2__rowt3__col1__result">
                  &bull;&nbsp;&nbsp;{{ getBet['Doble'][1].result }}
                </div>
                <template v-if="getBet['Doble'][1].teamAcronym">
                  <div class="bpr__pao__containerinfo__table2__rowt3__col1__team-logo">
                    <img :src="this.getTeamLogoUrl(getBet['Doble'][1].teamLogo)" alt="" />
                  </div>
                  <div v-if="isAcronymVisible">
                    {{ getBet['Doble'][1].teamAcronym }}
                  </div>
                </template>
              </div>
              <div class="bpr__pao__containerinfo__table1__rowt3__col2 ttext-5">{{ getBet['Doble'][1].prob }}%</div>
              <div
                class="bpr__pao__containerinfo__table1__rowt3__col3 ttext-6"
                @click="openCalienteBet(getBet['Doble'][1])"
              >
                <template v-if="getBet['Doble'][1].odd < 1">{{ getBet['Doble'][1].odd }}</template>
                <template v-else>+{{ getBet['Doble'][1].odd }}</template>
              </div>
            </div>
            <div
              :class="{
                'green-select':
                  getBet['MaxProb'][0].prob == getBet['Doble'][0].prob && getBet['MaxProb'][0].type_team == 0,
              }"
              class="bpr__pao__containerinfo__table1__rowt3"
            >
              <div class="bpr__pao__containerinfo__table1__rowt3__col1 ttext-4">
                <div class="bpr__pao__containerinfo__table2__rowt3__col1__result">
                  &bull;&nbsp;&nbsp;{{ getBet['Doble'][0].result }}
                </div>
                <template v-if="getBet['Doble'][0].teamAcronym">
                  <div class="bpr__pao__containerinfo__table2__rowt3__col1__team-logo">
                    <img :src="this.getTeamLogoUrl(getBet['Doble'][0].teamLogo)" alt="" />
                  </div>
                  <div v-if="isAcronymVisible">
                    {{ getBet['Doble'][0].teamAcronym }}
                  </div>
                </template>
              </div>
              <div class="bpr__pao__containerinfo__table1__rowt3__col2 ttext-5">{{ getBet['Doble'][0].prob }}%</div>
              <div
                class="bpr__pao__containerinfo__table1__rowt3__col3 ttext-6"
                @click="openCalienteBet(getBet['Doble'][0])"
              >
                <template v-if="getBet['Doble'][0].odd < 1">{{ getBet['Doble'][0].odd }}</template>
                <template v-else>+{{ getBet['Doble'][0].odd }}</template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { doBetcaliente } from '@/utils/analytics';
export default {
  name: 'CBetPerResult',
  props: {
    awaitForBets: {
      type: Boolean,
      default: false,
    },
    parlayBets: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      areBetGamesFilled: false,
      teamsLogoUrl: 'https://az755631.vo.msecnd.net/teams-80/',
    };
  },
  computed: {
    ...mapState('betsGeneral', ['selectedGame']),
    ...mapState(['displayWidth']),
    ...mapGetters('betsGeneral', ['getBet', 'getBetGames']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats']),
    moneyLineMaxProbName() {
      const maxProb = Object.values(this.getBet['Moneyline']).reduce(
        (accumulator, bet) => {
          return bet.prob > accumulator.prob ? bet : accumulator;
        },
        { prob: 0, result: '', teamAcronym: '' },
      );
      return this.getBetId(maxProb);
    },
    isAcronymVisible() {
      return this.displayWidth > 375;
    },
  },
  watch: {
    selectedGame: {
      deep: true,
      handler(newValue) {
        this.getBetGame(newValue.game_id);
      },
    },
    getBetGames() {
      this.getBetGame(this.selectedGame.game_id);
    },
    parlayBets: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue.tournaments && newValue.tournaments.length) {
          this.fillBetGames(newValue);
        }
      },
    },
  },
  methods: {
    ...mapActions('betsGeneral', ['getBetPerResult', 'getBetGame']),
    ...mapMutations('betsGeneral', ['setBetsGames']),
    getBetId(bet) {
      return `${bet.result}${bet.teamAcronym}`;
    },
    openCalienteBet(bet) {
      const index = bet.bet_ref.indexOf('BET_REF=');
      const betRef = bet.bet_ref.slice(index + 8);
      doBetcaliente({
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
        value: betRef,
        odd: bet.odd,
        section: 'Moneyline/Doble oportunidad',
        game_id: this.selectedGame.game_id,
        bet_name: bet.name,
      });
      window.open(bet.bet_ref, '_blank');
    },
    getTeamLogoUrl(teamLogo) {
      return `${this.teamsLogoUrl}${teamLogo}`;
    },
    fillBetGames(bets) {
      if (this.areBetGamesFilled) {
        return;
      }
      this.areBetGamesFilled = true;
      let betsGamesArray = {};
      let betGames = {};
      for (const idTournament in bets.tournaments) {
        for (const idbet in bets.tournaments[idTournament].bets) {
          if (betsGamesArray[bets.tournaments[idTournament].bets[idbet].game_id]) {
            betsGamesArray[bets.tournaments[idTournament].bets[idbet].game_id].push(
              bets.tournaments[idTournament].bets[idbet],
            );
          } else {
            betsGamesArray[bets.tournaments[idTournament].bets[idbet].game_id] = [];
            betsGamesArray[bets.tournaments[idTournament].bets[idbet].game_id].push(
              bets.tournaments[idTournament].bets[idbet],
            );
          }
        }
      }

      for (const idGame in betsGamesArray) {
        var maxima = 0;
        for (const idbet in betsGamesArray[idGame]) {
          if (!betGames[idGame]) {
            betGames[idGame] = {};
            betGames[idGame]['Doble'] = [];
            betGames[idGame]['Moneyline'] = [];
            betGames[idGame]['MaxProb'] = [];
            betGames[idGame]['Doble'][0] = {};
            betGames[idGame]['Doble'][1] = {};
            betGames[idGame]['Moneyline'][0] = {};
            betGames[idGame]['Moneyline'][1] = {};
            betGames[idGame]['Moneyline'][2] = {};
          }
          if (betsGamesArray[idGame][idbet].bet_type_id == 2) {
            if (betsGamesArray[idGame][idbet].bet_subtype_id == 3) {
              if (betsGamesArray[idGame][idbet].bet_suggested.away_team_logo != '') {
                betGames[idGame]['Doble'][0]['result'] = 'Gana/Empata';
                betGames[idGame]['Doble'][0]['teamAcronym'] = betsGamesArray[idGame][idbet].away_team_acronym;
                betGames[idGame]['Doble'][0]['teamLogo'] = betsGamesArray[idGame][idbet].away_team_logo;
                betGames[idGame]['Doble'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
                betGames[idGame]['Doble'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['Doble'][0]['bet_ref'] =
                  'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                  betsGamesArray[idGame][idbet].bet_ref;
                if (maxima <= betsGamesArray[idGame][idbet].golstats_probability) {
                  maxima = betsGamesArray[idGame][idbet].golstats_probability;
                  betGames[idGame]['MaxProb'][0] = {};
                  betGames[idGame]['MaxProb'][0]['result'] = 'Gana/Empata';
                  betGames[idGame]['MaxProb'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
                  betGames[idGame]['MaxProb'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                  betGames[idGame]['MaxProb'][0]['bet_ref'] =
                    'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                    betsGamesArray[idGame][idbet].bet_ref;
                  betGames[idGame]['MaxProb'][0]['logo'] = betsGamesArray[idGame][idbet].away_team_logo;
                  betGames[idGame]['MaxProb'][0]['type_team'] = 0;
                }
              }
              if (betsGamesArray[idGame][idbet].bet_suggested.home_team_logo != '') {
                betGames[idGame]['Doble'][1]['result'] = 'Gana/Empata ';
                betGames[idGame]['Doble'][1]['teamAcronym'] = betsGamesArray[idGame][idbet].home_team_acronym;
                betGames[idGame]['Doble'][1]['teamLogo'] = betsGamesArray[idGame][idbet].home_team_logo;
                betGames[idGame]['Doble'][1]['odd'] = betsGamesArray[idGame][idbet].odd;
                betGames[idGame]['Doble'][1]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['Doble'][1]['bet_ref'] =
                  'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                  betsGamesArray[idGame][idbet].bet_ref;
                if (maxima <= betsGamesArray[idGame][idbet].golstats_probability) {
                  maxima = betsGamesArray[idGame][idbet].golstats_probability;
                  betGames[idGame]['MaxProb'][0] = {};
                  betGames[idGame]['MaxProb'][0]['result'] = 'Gana/Empata';
                  betGames[idGame]['MaxProb'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
                  betGames[idGame]['MaxProb'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                  betGames[idGame]['MaxProb'][0]['bet_ref'] =
                    'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                    betsGamesArray[idGame][idbet].bet_ref;
                  betGames[idGame]['MaxProb'][0]['logo'] = betsGamesArray[idGame][idbet].home_team_logo;
                  betGames[idGame]['MaxProb'][0]['type_team'] = 1;
                }
              }
            }
          }
          if (betsGamesArray[idGame][idbet].bet_type_id == 1) {
            if (betsGamesArray[idGame][idbet].bet_subtype_id == 1) {
              if (betsGamesArray[idGame][idbet].bet_suggested.away_team_logo != '') {
                betGames[idGame]['Moneyline'][0]['result'] = 'Gana';
                betGames[idGame]['Moneyline'][0]['teamAcronym'] = betsGamesArray[idGame][idbet].away_team_acronym;
                betGames[idGame]['Moneyline'][0]['teamLogo'] = betsGamesArray[idGame][idbet].away_team_logo;
                betGames[idGame]['Moneyline'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
                betGames[idGame]['Moneyline'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['Moneyline'][0]['bet_ref'] =
                  'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                  betsGamesArray[idGame][idbet].bet_ref;
                if (maxima <= betsGamesArray[idGame][idbet].golstats_probability) {
                  maxima = betsGamesArray[idGame][idbet].golstats_probability;
                  betGames[idGame]['MaxProb'][0] = {};
                  betGames[idGame]['MaxProb'][0]['result'] = 'Gana ';
                  betGames[idGame]['MaxProb'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
                  betGames[idGame]['MaxProb'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                  betGames[idGame]['MaxProb'][0]['bet_ref'] =
                    'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                    betsGamesArray[idGame][idbet].bet_ref;
                  betGames[idGame]['MaxProb'][0]['logo'] = betsGamesArray[idGame][idbet].away_team_logo;
                  betGames[idGame]['MaxProb'][0]['type_team'] = 0;
                }
              }
              if (betsGamesArray[idGame][idbet].bet_suggested.home_team_logo != '') {
                betGames[idGame]['Moneyline'][1]['result'] = 'Gana';
                betGames[idGame]['Moneyline'][1]['teamAcronym'] = betsGamesArray[idGame][idbet].home_team_acronym;
                betGames[idGame]['Moneyline'][1]['teamLogo'] = betsGamesArray[idGame][idbet].home_team_logo;
                betGames[idGame]['Moneyline'][1]['odd'] = betsGamesArray[idGame][idbet].odd;
                betGames[idGame]['Moneyline'][1]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['Moneyline'][1]['bet_ref'] =
                  'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                  betsGamesArray[idGame][idbet].bet_ref;
                if (maxima <= betsGamesArray[idGame][idbet].golstats_probability) {
                  maxima = betsGamesArray[idGame][idbet].golstats_probability;
                  betGames[idGame]['MaxProb'][0] = {};
                  betGames[idGame]['MaxProb'][0]['result'] = 'Gana';
                  betGames[idGame]['MaxProb'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
                  betGames[idGame]['MaxProb'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                  betGames[idGame]['MaxProb'][0]['bet_ref'] =
                    'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                    betsGamesArray[idGame][idbet].bet_ref;
                  betGames[idGame]['MaxProb'][0]['logo'] = betsGamesArray[idGame][idbet].home_team_logo;
                  betGames[idGame]['MaxProb'][0]['type_team'] = 1;
                }
              }
            }
            if (betsGamesArray[idGame][idbet].bet_subtype_id == 2) {
              betGames[idGame]['Moneyline'][2]['result'] = 'Empate';
              betGames[idGame]['Moneyline'][2]['odd'] = betsGamesArray[idGame][idbet].odd;
              betGames[idGame]['Moneyline'][2]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
              betGames[idGame]['Moneyline'][2]['bet_ref'] =
                'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                betsGamesArray[idGame][idbet].bet_ref;
              if (maxima < betsGamesArray[idGame][idbet].golstats_probability) {
                maxima = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['MaxProb'][0] = {};
                betGames[idGame]['MaxProb'][0]['result'] = 'Empate';
                betGames[idGame]['MaxProb'][0]['odd'] = betsGamesArray[idGame][idbet].odd;
                betGames[idGame]['MaxProb'][0]['prob'] = betsGamesArray[idGame][idbet].golstats_probability;
                betGames[idGame]['MaxProb'][0]['bet_ref'] =
                  'https://online.caliente.mx/page?member=goalstats&campaign=DEFAULT&channel=DEFAULT&zone=54300998&lp=57942232&BET_REF=' +
                  betsGamesArray[idGame][idbet].bet_ref;
                betGames[idGame]['MaxProb'][0]['logo'] = 'none';
                betGames[idGame]['MaxProb'][0]['type_team'] = 3;
              }
            }
          }
        }
      }
      this.setBetsGames(betGames);
    },
  },
  async created() {
    if (!this.awaitForBets) {
      await this.getBetPerResult();
    }
  },
};
</script>
<style lang="scss">
.odd_textdecoration {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    color: #9ccb1c;
  }
}
.green-select {
  border-radius: 4px;
  background-color: #cbee6b;
}
.movil-section {
  display: none !important;
  @media screen and (max-width: 563px) {
    display: inline-block !important;
  }
}
.desktop-section {
  display: inline-block;
  @media screen and (max-width: 563px) {
    display: none !important;
  }
}
.icon-question {
  width: 29px;
  height: 29px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 2px 10px;
  border-radius: 25px;
  background-color: #132839;
  font-family: 'Heebo';
  font-size: 17px;
  font-weight: 500;
  margin-top: 3px;
  margin-left: 5px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.icon-question:hover {
  background: #404548;
  cursor: pointer;
}
.btn-precision {
  width: 253px;
  visibility: hidden;
  height: 41px;
  display: inline-flex;
  border-radius: 10px;
  border: solid 2px #e9eef1;
  &__text {
    width: 200px;
    height: 41px;
    font-family: 'Heebo';
    line-height: 38px;
    font-size: 17px;
    float: left;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: right;
    padding-right: 3px;
    color: #000;
  }
  &__iconcont {
    float: left;
    width: 48px;
    height: 41px;
  }
}
.ttext-1 {
  font-family: 'Roboto-Bold';
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  @media screen and (max-width: 663px) {
    font-size: 15px;
  }
  @media screen and (max-width: 563px) {
    font-size: 17px;
  }
  @media screen and (max-width: 363px) {
    font-size: 15px;
  }
}
.ttext-2 {
  font-family: 'Roboto-Regular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  @media screen and (max-width: 663px) {
    font-size: 12px;
  }
  @media screen and (max-width: 563px) {
    font-size: 14px;
  }
  @media screen and (max-width: 363px) {
    font-size: 12px;
  }
}
.ttext-3 {
  font-family: 'Roboto-Black';
  font-size: 14px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  @media screen and (max-width: 663px) {
    font-size: 12px;
  }
  @media screen and (max-width: 563px) {
    font-size: 14px;
  }
  @media screen and (max-width: 363px) {
    font-size: 12px;
  }
}
.ttext-4 {
  font-family: 'Roboto-Regular';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  padding-left: 5px;
  @media screen and (max-width: 663px) {
    font-size: 13px;
  }
  @media screen and (max-width: 663px) {
    font-size: 15px;
  }
  @media screen and (max-width: 363px) {
    font-size: 13px;
  }
}
.ttext-5 {
  font-family: 'Roboto-Medium';
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.ttext-6 {
  font-family: 'Roboto-Black';
  font-size: 15px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  text-decoration: underline;
  &:hover {
    color: #9ccb1c;
    cursor: pointer;
  }
}
.bpr {
  width: 963px;
  height: auto;

  margin: 0 auto;
  display: inline-flex;
  @media screen and (max-width: 963px) {
    width: 913px;
  }
  @media screen and (max-width: 913px) {
    width: 863px;
  }
  @media screen and (max-width: 863px) {
    width: 813px;
  }
  @media screen and (max-width: 813px) {
    width: 763px;
  }
  @media screen and (max-width: 763px) {
    width: 713px;
  }
  @media screen and (max-width: 713px) {
    width: 663px;
  }
  @media screen and (max-width: 663px) {
    width: 613px;
  }
  @media screen and (max-width: 613px) {
    width: 563px;
  }
  @media screen and (max-width: 563px) {
    width: 513px;
    display: inline-block;
  }
  @media screen and (max-width: 513px) {
    width: 463px;
  }
  @media screen and (max-width: 463px) {
    width: 413px;
  }
  @media screen and (max-width: 413px) {
    width: 363px;
  }
  @media screen and (max-width: 363px) {
    width: 313px;
  }
  @media screen and (max-width: 313px) {
    width: 280px;
  }
  &__pao {
    width: 546px;
    height: 290px;
    @media screen and (max-width: 963px) {
      width: 526px;
    }
    @media screen and (max-width: 913px) {
      width: 506px;
    }
    @media screen and (max-width: 863px) {
      width: 486px;
    }
    @media screen and (max-width: 813px) {
      width: 463px;
    }
    @media screen and (max-width: 713px) {
      width: 372px;
    }
    @media screen and (max-width: 663px) {
      width: 322px;
    }
    @media screen and (max-width: 613px) {
      width: 292px;
    }
    @media screen and (max-width: 563px) {
      width: 513px;
    }
    @media screen and (max-width: 513px) {
      width: 463px;
    }
    @media screen and (max-width: 463px) {
      width: 413px;
    }
    @media screen and (max-width: 413px) {
      width: 363px;
    }
    @media screen and (max-width: 363px) {
      width: 313px;
    }
    @media screen and (max-width: 313px) {
      width: 280px;
    }
    &__containerinfo {
      width: 409px;
      height: 290px;
      float: right;
      margin-right: 37px;
      @media screen and (max-width: 813px) {
        margin-right: 17px;
      }
      @media screen and (max-width: 763px) {
        margin-right: 0px;
      }
      @media screen and (max-width: 713px) {
        width: 370px;
      }
      @media screen and (max-width: 663px) {
        width: 320px;
      }
      @media screen and (max-width: 613px) {
        width: 290px;
      }
      @media screen and (max-width: 563px) {
        width: 409px;
        float: none;
        margin: 0 auto;
      }
      @media screen and (max-width: 413px) {
        width: 364px;
      }
      @media screen and (max-width: 363px) {
        width: 314px;
      }
      @media screen and (max-width: 313px) {
        width: 280px;
      }
      &__table1 {
        width: 409px;
        height: 110px;
        @media screen and (max-width: 713px) {
          width: 370px;
        }
        @media screen and (max-width: 663px) {
          width: 320px;
        }
        @media screen and (max-width: 613px) {
          width: 290px;
        }
        @media screen and (max-width: 563px) {
          width: 409px;
        }
        @media screen and (max-width: 413px) {
          width: 364px;
        }
        @media screen and (max-width: 363px) {
          width: 314px;
        }
        @media screen and (max-width: 313px) {
          width: 280px;
        }
        &__rowt1 {
          width: 409px;
          height: 18px;
          margin-bottom: 10px;
          display: inline-flex;
          @media screen and (max-width: 713px) {
            width: 370px;
          }
          @media screen and (max-width: 663px) {
            width: 320px;
          }
          @media screen and (max-width: 613px) {
            width: 290px;
          }
          @media screen and (max-width: 563px) {
            width: 409px;
          }
          @media screen and (max-width: 413px) {
            width: 364px;
          }
          @media screen and (max-width: 363px) {
            width: 314px;
          }
          @media screen and (max-width: 313px) {
            width: 280px;
          }
          &__col1 {
            width: 212px;
            height: 18px;
            line-height: 18px;
          }
          &__col2 {
            width: 90px;
            height: 18px;
            line-height: 18px;
          }
          &__col3 {
            width: 107px;
            height: 18px;
            line-height: 18px;
          }
        }
        &__rowt2 {
          width: 409px;
          height: 20px;
          margin-bottom: 10px;
          display: inline-flex;
          @media screen and (max-width: 713px) {
            width: 370px;
          }
          @media screen and (max-width: 663px) {
            width: 320px;
          }
          @media screen and (max-width: 613px) {
            width: 290px;
          }
          @media screen and (max-width: 563px) {
            width: 409px;
          }
          @media screen and (max-width: 413px) {
            width: 364px;
          }
          @media screen and (max-width: 363px) {
            width: 314px;
          }
          @media screen and (max-width: 313px) {
            width: 280px;
          }
          &__col1 {
            width: 212px;
            height: 20px;
            line-height: 20px;
          }
          &__col2 {
            width: 90px;
            height: 20px;
            line-height: 20px;
          }
          &__col3 {
            width: 107px;
            height: 20px;
            line-height: 20px;
            &:hover {
              cursor: pointer;
            }
          }
        }
        &__rowt3 {
          width: 409px;
          height: 24px;
          margin-bottom: 4px;
          display: inline-flex;
          @media screen and (max-width: 713px) {
            width: 370px;
          }
          @media screen and (max-width: 663px) {
            width: 320px;
          }
          @media screen and (max-width: 613px) {
            width: 290px;
          }
          @media screen and (max-width: 563px) {
            width: 409px;
          }
          @media screen and (max-width: 413px) {
            width: 364px;
          }
          @media screen and (max-width: 363px) {
            width: 314px;
          }
          @media screen and (max-width: 313px) {
            width: 280px;
          }
          &__col1 {
            width: 212px;
            height: 24px;
            line-height: 24px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__result {
              margin-right: 0.4rem;
            }

            &__team-logo {
              height: 24px;
              width: 24px;
              margin-right: 0.4rem;
            }
          }
          &__col2 {
            width: 90px;
            height: 24px;
            line-height: 24px;
          }
          &__col3 {
            width: 107px;
            height: 24px;
            line-height: 24px;
          }
        }
      }
      &__table2 {
        width: 409px;
        height: 110px;
        margin-top: 48px;
        @media screen and (max-width: 713px) {
          width: 370px;
        }
        @media screen and (max-width: 663px) {
          width: 320px;
        }
        @media screen and (max-width: 613px) {
          width: 290px;
        }
        @media screen and (max-width: 563px) {
          width: 409px;
        }
        @media screen and (max-width: 413px) {
          width: 364px;
        }
        @media screen and (max-width: 363px) {
          width: 314px;
        }
        @media screen and (max-width: 313px) {
          width: 280px;
        }
        &__rowt2 {
          width: 409px;
          height: 20px;
          margin-bottom: 10px;
          display: inline-flex;
          @media screen and (max-width: 713px) {
            width: 370px;
          }
          @media screen and (max-width: 663px) {
            width: 320px;
          }
          @media screen and (max-width: 613px) {
            width: 290px;
          }
          @media screen and (max-width: 563px) {
            width: 409px;
          }
          @media screen and (max-width: 413px) {
            width: 364px;
          }
          @media screen and (max-width: 363px) {
            width: 314px;
          }
          @media screen and (max-width: 313px) {
            width: 280px;
          }
          &__col1 {
            width: 212px;
            height: 20px;
            line-height: 20px;
          }
          &__col2 {
            width: 90px;
            height: 20px;
            line-height: 20px;
          }
          &__col3 {
            width: 107px;
            height: 20px;
            line-height: 20px;
          }
        }
        &__rowt3 {
          width: 409px;
          height: 24px;
          margin-bottom: 4px;
          display: inline-flex;
          @media screen and (max-width: 713px) {
            width: 370px;
          }
          @media screen and (max-width: 663px) {
            width: 320px;
          }
          @media screen and (max-width: 613px) {
            width: 290px;
          }
          @media screen and (max-width: 563px) {
            width: 409px;
          }
          @media screen and (max-width: 413px) {
            width: 364px;
          }
          @media screen and (max-width: 363px) {
            width: 314px;
          }
          @media screen and (max-width: 313px) {
            width: 280px;
          }
          &__col1 {
            width: 212px;
            height: 24px;
            line-height: 24px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            &__result {
              margin-right: 0.4rem;
            }

            &__team-logo {
              height: 24px;
              width: 24px;
              margin-right: 0.4rem;
            }
          }
          &__col2 {
            width: 90px;
            height: 24px;
            line-height: 24px;
          }
          &__col3 {
            width: 107px;
            height: 24px;
            line-height: 24px;
          }
        }
      }
    }
  }
  &__hp {
    width: 317px;
    height: 290px;
    float: left;
    @media screen and (max-width: 763px) {
      width: 304px;
    }
    @media screen and (max-width: 713px) {
      width: 290px;
    }
    @media screen and (max-width: 613px) {
      width: 270px;
    }
    @media screen and (max-width: 563px) {
      width: 513px;
      float: none;
      margin: 0 auto;
      display: inline-block;
      height: 300px;
    }
    @media screen and (max-width: 513px) {
      width: 463px;
    }
    @media screen and (max-width: 463px) {
      width: 413px;
    }
    @media screen and (max-width: 413px) {
      width: 363px;
    }
    @media screen and (max-width: 363px) {
      width: 313px;
    }
    @media screen and (max-width: 313px) {
      width: 280px;
    }
    &__containerinfo {
      width: 317px;
      height: 290px;
      float: left;
      @media screen and (max-width: 763px) {
        width: 304px;
      }
      @media screen and (max-width: 713px) {
        width: 290px;
      }
      @media screen and (max-width: 613px) {
        width: 270px;
      }
      @media screen and (max-width: 563px) {
        width: 317px;
        float: none;
        margin: 0 auto;
      }
      @media screen and (max-width: 363px) {
        width: 313px;
      }
      @media screen and (max-width: 313px) {
        width: 280px;
      }
      &__rowimg {
        width: 124px;
        height: 124px;
        margin: 0 auto;
        margin-top: 10px;
      }
      &__rowtitle {
        width: 193px;
        height: 22px;
        margin: 0 auto;
        margin-top: 15px;
        font-size: 30px;
        font-family: 'Roboto-Bold';
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.73;
        letter-spacing: normal;
        text-align: center;
        color: #404548;
      }
      &__rowstat {
        width: 181px;
        height: 38px;
        margin: 0 auto;
        margin-top: 15px;
        &__sep {
          width: 1px;
          height: 38px;
          float: left;
        }
        &__item {
          width: 90px;
          height: 38px;
          float: left;
          &__percent {
            width: 90px;
            height: 22px;
            float: left;
            font-family: 'Roboto-Bold';
            font-size: 23px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 22px;
            letter-spacing: normal;
            text-align: center;
            color: #404548;
            @media screen and (max-width: 563px) {
              font-size: 30px;
            }
          }
          &__title {
            width: 90px;
            height: 16px;
            float: left;
            font-family: 'Roboto-Medium';
            font-size: 11px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            letter-spacing: normal;
            text-align: center;
            color: #616367;
            @media screen and (max-width: 563px) {
              height: 25px;
              line-height: 25px;
            }
          }
        }
      }
      &__rowprecision {
        width: 253px;
        height: 41px;
        margin: 0 auto;
        margin-top: 15px;
      }
    }
  }
}
</style>
